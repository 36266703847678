<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['foodAllergies', 'otherFoodAllergies'])">
      <multi-answer-question
        title="label.foodAllergies"
        :value="foodAllergies"
        :other-value="otherFoodAllergies"
        :options="$options.foodAllergiesOptions"
        @input="onFieldChange('foodAllergies', $event)"
        @other-input="onFieldChange('otherFoodAllergies', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  FOOD_ALLERGY,
  OTHER_VALUE_INCLUSIVE_BINDINGS
} from '@/modules/questionnaire/api/constants';

const FOOD_ALLERGIES_OPTIONS = [
  { value: FOOD_ALLERGY.FRUIT, text: 'foodAllergy.fruit' },
  { value: FOOD_ALLERGY.RICE, text: 'foodAllergy.rice' },
  { value: FOOD_ALLERGY.GARLIC, text: 'foodAllergy.garlic' },
  { value: FOOD_ALLERGY.OATS, text: 'foodAllergy.oats' },
  { value: FOOD_ALLERGY.MEAT, text: 'foodAllergy.meat' },
  { value: FOOD_ALLERGY.MILK, text: 'foodAllergy.milk' },
  { value: FOOD_ALLERGY.PEANUTS, text: 'foodAllergy.peanuts' },
  { value: FOOD_ALLERGY.FISH_OR_SHELLFISH, text: 'foodAllergy.fishOrShellfish' },
  { value: FOOD_ALLERGY.SOY, text: 'foodAllergy.soy' },
  { value: FOOD_ALLERGY.TREE_NUTS, text: 'foodAllergy.treeNuts' },
  { value: FOOD_ALLERGY.WHEAT, text: 'foodAllergy.wheat' },
  { value: FOOD_ALLERGY.HOT_PEPPERS, text: 'foodAllergy.hotPeppers' },
  { value: FOOD_ALLERGY.GLUTEN, text: 'foodAllergy.gluten' },
  { value: FOOD_ALLERGY.EGGS, text: 'foodAllergy.eggs' },
  { value: FOOD_ALLERGY.TARTRAZINE, text: 'foodAllergy.tartrazine' },
  { value: FOOD_ALLERGY.SULFITES, text: 'foodAllergy.sulfites' },
  { ...OTHER_VALUE_INCLUSIVE_BINDINGS, text: 'foodAllergy.other' }
];

export default {
  name: 'FoodAllergiesTemplate',
  components: { MultiAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  foodAllergiesOptions: FOOD_ALLERGIES_OPTIONS,
  props: {
    foodAllergies: {
      type: Array,
      required: true
    },
    otherFoodAllergies: {
      type: String,
      required: true
    }
  }
};
</script>
